<template>
  <v-card>
    <v-card-text class="filters">
      <v-row justify="space-between">
        <v-col cols="8">
          <div class="d-flex">
            <ac-select
              label="Company"
              v-model="selectedCompanies"
              :items="companies"
              class="company-select me-3 flex-grow-0"
              multiple
              chips
              clearable
            />
            <ac-select
              label="Users"
              v-model="selectedUser"
              :items="users"
              class="user-select me-3 flex-grow-0"
              clearable
            />
            <ac-select
              label="Year"
              v-model="selectedYear"
              :items="years"
              class="year-select me-3 flex-grow-0"
              clearable
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="table">
      <v-data-table
        :headers="tableHeader"
        :items="tableData"
        :loading="loading"
        class="text-no-wrap"
      >
        <template #[`item.actions`]>
          <ac-icon-btn
            btn-class="me-2"
            text="View"
            :icon="icons.mdiEye"
            color="success"
            @click="modalVisible = true"
          />
          <ac-icon-btn text="Download" :icon="icons.mdiCloudDownload" color="info" />
        </template>
      </v-data-table>
    </v-card-text>
    <training-information-modal v-model="modalVisible" />
  </v-card>
</template>

<script>
import AcSelect from '@/components/AcSelect.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'
import TrainingInformationModal from '@/components/CompletedVideos/TrainingInformationModal.vue'
import { mdiEye, mdiCloudDownload } from '@mdi/js'
export default {
  components: {
    AcSelect,
    AcIconBtn,
    TrainingInformationModal,
  },
  data: () => ({
    icons: {
      mdiEye,
      mdiCloudDownload,
    },
    modalVisible: false,
    selectedCompanies: null,
    companies: ['Company 1', 'Company 2', 'Company 3'],
    selectedUser: null,
    users: [
      {
        label: 'User 1',
        value: 1,
      },
      {
        label: 'User 2',
        value: 2,
      },
      {
        label: 'User 3',
        value: 3,
      },
    ],
    selectedYear: '2022',
    years: ['2022', '2021', '2020', '2019'],

    // * table
    loading: false,
    tableHeader: [
      { text: 'USER NAME', value: 'userName' },
      { text: 'DATE', value: 'date' },
      { text: 'NAME', value: 'name' },
      { text: 'DESCRIPTION', value: 'desc' },
      { text: 'TIME ALLOCATION', value: 'timeAllocation' },
      { text: 'NUMBER OF QUIZZES', value: 'quizzes' },
      { text: 'TOTAL QUESTIONS', value: 'questions' },
      { text: 'TOTAL CORRECT', value: 'totalCorrect' },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ],
    tableData: [
      {
        userName: 'Warren Yahoo',
        date: '18 Apr 2022 23:21:37',
        name: 'Test video 12th March 22',
        desc: 'description',
        timeAllocation: '15m',
        quizzes: '1',
        questions: '3',
        totalCorrect: '3',
      },
      {
        userName: 'Warren Yahoo',
        date: '18 Apr 2022 23:21:37',
        name: 'Test video 12th March 22',
        desc: 'description',
        timeAllocation: '15m',
        quizzes: '1',
        questions: '3',
        totalCorrect: '3',
      },
      {
        userName: 'Warren Yahoo',
        date: '18 Apr 2022 23:21:37',
        name: 'Test video 12th March 22',
        desc: 'description',
        timeAllocation: '15m',
        quizzes: '1',
        questions: '3',
        totalCorrect: '3',
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.company-select,
.user-select {
  width: 20rem;
}
</style>
