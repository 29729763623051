<template>
  <v-card id="cpt-records" class="mt-3">
    <v-card-title> CPT Records </v-card-title>
    <v-card-text>
      <v-row justify="space-between">
        <v-col cols="6">
          <div class="d-flex">
            <ac-select
              label="Company"
              v-model="selectedCompany"
              :items="companies"
              class="company-select me-3 flex-grow-0"
              multiple
              chips
              clearable
            />
            <ac-select
              label="Users"
              v-model="selectedUsers"
              :items="users"
              class="user-select me-3 flex-grow-0"
              multiple
              chips
              clearable
            />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="table">
      <v-data-table
        :headers="tableHeader"
        :items="tableData"
        :loading="loading"
        class="text-no-wrap"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import AcSelect from '@/components/AcSelect.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'

import { mdiEye, mdiCloudDownload } from '@mdi/js'
export default {
  components: {
    AcSelect,
    AcIconBtn,
  },
  data: () => ({
    icons: {
      mdiEye,
      mdiCloudDownload,
    },
    loading: false,
    selectedCompany: null,
    companies: ['Company 1', 'Company 2', 'Company 3'],
    selectedUsers: null,
    users: [
      {
        label: 'User 1',
        value: 1,
      },
      {
        label: 'User 2',
        value: 2,
      },
      {
        label: 'User 3',
        value: 3,
      },
    ],
    // * table
    tableHeader: [
      { text: 'FULL NAME', value: 'fullName' },
      { text: 'COMPANY', value: 'company' },
      { text: 'LICENSES', value: 'licenses' },
      { text: 'CPT LEFT', value: 'cptLeft' },
    ],
    tableData: [
      {
        fullName: 'Julian Lim',
        company: 'Happy Alpha Investment Company',
        licenses: '2, 1, 5, 4, 11, 9, 6, 8',
        cptLeft: '40h',
      },
      {
        fullName: 'Julian Lim',
        company: 'Happy Alpha Investment Company',
        licenses: '2, 1, 5, 4, 11, 9, 6, 8',
        cptLeft: '40h',
      },
      {
        fullName: 'Julian Lim',
        company: 'Happy Alpha Investment Company',
        licenses: '2, 1, 5, 4, 11, 9, 6, 8',
        cptLeft: '40h',
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.company-select,
.user-select {
  width: 20rem;
}
</style>
