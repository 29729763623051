<template>
  <v-dialog v-model="value" persistent max-width="600px">
    <v-card>
      <v-card-title class="bg-gradient-primary-2">
        <span class="headline white--text">Training Information</span>
      </v-card-title>
      <div class="body">
        <v-card-text class="pt-4">
          <div class="info-block mb-2 text-sm elevation-2">
            <div class="label bg-gradient-primary-2 px-4 py-1 elevation-2 rounded">
              Company Name
            </div>
            <div class="value">Dreamteam AM Ltd User</div>
          </div>
          <div class="info-block mb-2 text-sm elevation-2">
            <div class="label bg-gradient-primary-2 px-4 py-1 elevation-2 rounded">User Name</div>
            <div class="value">Warren Yahoo</div>
          </div>
          <div class="info-block mb-2 text-sm elevation-2">
            <div class="label bg-gradient-primary-2 px-4 py-1 elevation-2 rounded">
              Date of Completion
            </div>
            <div class="value">18 Apr 2022 23:21:37</div>
          </div>
          <div class="info-block mb-2 text-sm elevation-2">
            <div class="label bg-gradient-primary-2 px-4 py-1 elevation-2 rounded">Training</div>
            <div class="value">Test video 12th March 22</div>
          </div>
          <div class="info-block text-sm elevation-2 rounded">
            <div class="label bg-gradient-primary-2 px-4 py-1 elevation-2 rounded">
              Duration Eligible for CPT
            </div>
            <div class="value">15m</div>
          </div>
        </v-card-text>
        <v-card-text>
          <v-expansion-panels v-model="quizExpand">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="font-weight-bold"> Quizzes </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-for="n in 4" :key="n" class="quiz elevation-2 rounded mb-2">
                  <div class="quiz-heading mb-2 text-sm rounded">
                    <div
                      class="label bg-gradient-primary-2 px-4 py-1 elevation-2 rounded rounded-tl"
                    >
                      Name
                    </div>
                    <div class="value">Advanced Training Quiz</div>
                  </div>
                  <div class="quiz-questions">
                    <div class="label bg-gradient-primary-2 px-4 py-1 elevation-2 rounded">
                      Questions
                    </div>
                    <div class="pa-2">
                      <div class="question">Lorem ipsum dolor sit amet.</div>
                      <div class="question">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      </div>
                      <div class="question">
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam, beatae?
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </div>

      <v-divider></v-divider>
      <v-card-actions>
        <ac-button title="Download Video" color="primary" @click="onClose" />

        <v-spacer></v-spacer>
        <ac-button title="Okay" color="success" @click="onClose" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AcButton from '@/components/AcButton.vue'

export default {
  name: 'TrainingInformationModal',
  components: {
    AcButton,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDialogVisible: false,
      quizExpand: null,
    }
  },
  methods: {
    onClose() {
      this.quizExpand = null
      this.$emit('input', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.body {
  max-height: 70vh;
  overflow-y: auto;
}
.info-block,
.quiz-heading {
  display: flex;
  align-items: center;
  .label {
    margin-right: 1em;
    color: white;
    font-weight: 600;
    width: 40%;
  }
}
.quiz {
  &-questions {
    .label {
      color: white;
      font-weight: 600;
      width: 40%;
    }
  }
}
</style>
