import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-3",attrs:{"id":"cpt-records"}},[_c(VCardTitle,[_vm._v(" CPT Records ")]),_c(VCardText,[_c(VRow,{attrs:{"justify":"space-between"}},[_c(VCol,{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex"},[_c('ac-select',{staticClass:"company-select me-3 flex-grow-0",attrs:{"label":"Company","items":_vm.companies,"multiple":"","chips":"","clearable":""},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}}),_c('ac-select',{staticClass:"user-select me-3 flex-grow-0",attrs:{"label":"Users","items":_vm.users,"multiple":"","chips":"","clearable":""},model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)])],1)],1),_c(VCardText,{staticClass:"table"},[_c(VDataTable,{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableHeader,"items":_vm.tableData,"loading":_vm.loading}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }