<template>
  <div>
    <ac-module-heading> Completed Videos </ac-module-heading>
    <MainSections />
    <CptRecordsSection />
  </div>
</template>

<script>
import MainSections from '@/components/CompletedVideos/MainSections.vue'
import CptRecordsSection from '@/components/CompletedVideos/CptRecordsSection.vue'

export default {
  name: 'CompletedVideos',
  components: {
    MainSections,
    CptRecordsSection,
  },
}
</script>

<style lang="scss" scoped>
.company-select,
.user-select {
  width: 20rem;
}
</style>
